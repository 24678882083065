import React from 'react';
import { connect } from 'react-redux';
import { rootSelector, State } from '../reducers/rootReducer';
import { BackButton } from './BackButton';
import { trainerActions } from '../actions/actions';
import { TrainingSetList } from './TraningSetList';

type StateProps = {
    isTrainingSetScreenOpen: boolean;
}

type DispatchProps = {
    onClick: () => void;
}

type Props = StateProps & DispatchProps

const TrainingSetScreenPresentational = (props: Props) => (
    <div className='price-modal'>
        <BackButton onClick={props.onClick} />
        <div style={{ fontSize: 12, color: '#828282' }}>
            You are practicing:
        </div>
        <div style={{ fontSize: 22, color: 'white' }}>
            Ikoria: Lair of Behemoths
        </div>
        <TrainingSetList />
    </div>

)

const mapStateToProps = (state: State): StateProps => {
    const isTrainingSetScreenOpen = rootSelector(state).isTrainingSetScreenOpen()
    return {
        isTrainingSetScreenOpen: isTrainingSetScreenOpen,
    }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    onClick: () => dispatch(trainerActions.CLOSED_TRAINING_SET_SCREEN(undefined))
})

export const TrainingSetScreen = connect(mapStateToProps, mapDispatchToProps)(TrainingSetScreenPresentational)