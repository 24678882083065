import React from 'react';

type OwnProps = {
    color: string;
    size: string;
    title: string;
    mode?: string;
    onPress: () => void;
}

type Props = OwnProps

export const TrainingSetButton = (props: Props) => (
    <div
        className={props.size}
        style={props.title === 'White' ? { backgroundColor: props.color, color: 'black' } : { backgroundColor: props.color }}
        onClick={props.onPress}
    >
        {props.title}
    </div>
)