import React from 'react'
import { rootSelector, State } from '../reducers/rootReducer'
import { connect } from 'react-redux'
import BarLoader from 'react-spinners/BarLoader'
import { BottomHalfBlocker } from './BottomHalfBlocker'

type StateProps = {
    cardImage: string;
}

type Props = StateProps

const QuizCardPresentational = (props: Props) => (
    <div className='image-container'>
        <div style={{ position: 'absolute', zIndex: -1 }}>
            <BarLoader width={150} color={'#006633'} />
        </div>
        <img src={props.cardImage} height={400} />
        <BottomHalfBlocker />
    </div>
)

const mapStateToProps = (state: State): StateProps => {
    const cardImage = rootSelector(state).quizCardAtPosition()
    console.log('cardImage is ', cardImage)
    return {
        cardImage: cardImage !== undefined ? (cardImage as any).image_uris.border_crop : ''
    }
}

export const QuizCard = connect(mapStateToProps)(QuizCardPresentational)