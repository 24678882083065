import React from 'react';
import './App.css';
import { LifeCard } from './components/LifeCard'
import { CenterConsole } from './components/CenterConsole'
import { PriceModal } from './components/PriceModal'
import { rootSelector, State } from './reducers/rootReducer';
import { connect } from 'react-redux';
import { TrainingSetScreen } from './components/TrainingSetScreen';
import { QuizScreen } from './components/QuizScreen';

type StateProps = {
    isPricerOpen: boolean;
    isTrainingSetScreenOpen: boolean;
    isQuizOpen: boolean;
}

type Props = StateProps

const AppPresentational = (props: Props) => (
    <div className="App" onClick={e => e.preventDefault()}>
        {props.isQuizOpen ?
            <QuizScreen />
            : null}
        {props.isPricerOpen ?
            <PriceModal />
            : null}
        {props.isTrainingSetScreenOpen ?
            <TrainingSetScreen />
            : null}
        <div style={{ margin: "10px", height: "100%", display: "flex", flexDirection: "column" }}>
            <LifeCard
                class="Lifecard-Top"
                playerNumber={1}
            />
            <CenterConsole />
            <LifeCard
                class="Lifecard-Bottom"
                playerNumber={2}
            />
        </div>
    </div>
)

const mapStateToProps = (state: State): StateProps => {
    const isPricerOpen = rootSelector(state).isPriceMode()
    const isTrainingSetScreenOpen = rootSelector(state).isTrainingSetScreenOpen()
    const isQuizOpen = rootSelector(state).isQuizScreenOpen()
    return {
        isPricerOpen: isPricerOpen,
        isTrainingSetScreenOpen: isTrainingSetScreenOpen,
        isQuizOpen: isQuizOpen
    }
}

export const App = connect(mapStateToProps)(AppPresentational)