import React from 'react';
import { LifeCardButton } from './LifeCardButton';
import { DiceContainer } from './DiceContainer';
import { State, rootSelector } from '../reducers/rootReducer';
import { connect } from 'react-redux';

type OwnProps = {
    class: string,
    playerNumber: number,
}

type StateProps = {
    diceMode: boolean,
    lifeTotal: number,
}

type Props = OwnProps & StateProps

export const LifeCardPresentational = (props: Props) => (
    <div className={props.class}>
        {props.diceMode ?
            <DiceContainer /> :
            <div style={{ height: '100%' }}>
                <div className="lifebutton-container">
                    <LifeCardButton shouldIncrement={false} playerNumber={props.playerNumber} />
                    <LifeCardButton shouldIncrement={true} playerNumber={props.playerNumber} />
                </div>
                <div className="life-total">{props.lifeTotal}</div>
            </div>
        }
    </div>
)

const mapStateToProps = (state: State, ownProps: OwnProps) => {
    const lifeTotal = ownProps.playerNumber === 1 ? rootSelector(state).player_one_life() : rootSelector(state).player_two_life()
    const diceMode = rootSelector(state).isDiceMode()
    return {
        lifeTotal: lifeTotal,
        diceMode: diceMode,
    }
}

export const LifeCard = connect(mapStateToProps)(LifeCardPresentational)
