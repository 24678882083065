import React from 'react';
import { TrainingSetButton } from './TraningSetButton'
import { isTrainerAction, trainerActions } from '../actions/actions';
import { connect } from 'react-redux';

const SMALL_BUTTON = 'small-training-set-button'
const LARGE_BUTTON = 'large-training-set-button'

const BLACK = '#262626'
const WHITE = '#FCFCFC'
const RED = '#7E001E'
const GREEN = '#006633'
const BLUE = '#264490'
const CLESS = '#8A8A8A'
const PURPLE = '#8B2C9A'
const GREY = '#343434'

const Spacer = () => <div style={{ padding: '10px' }} />

type DispatchProps = {
    onPress: (quizType: string) => void;
}

type Props = DispatchProps

export const TrainingSetListPresentational = (props: Props) => (
    <div className={'set-buttons-container'}>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <div className={'set-buttons-row'}>
            <TrainingSetButton title={'White'} color={WHITE} size={SMALL_BUTTON} onPress={() => props.onPress('white')} />
            <Spacer />
            <TrainingSetButton title={'Black'} color={BLACK} size={SMALL_BUTTON} onPress={() => props.onPress('black')} />
        </div>
        <Spacer />
        <div className={'set-buttons-row'}>
            <TrainingSetButton title={'Green'} color={GREEN} size={SMALL_BUTTON} onPress={() => props.onPress('green')} />
            <Spacer />
            <TrainingSetButton title={'Red'} color={RED} size={SMALL_BUTTON} onPress={() => props.onPress('red')} />
        </div>
        <Spacer />
        <div className={'set-buttons-row'}>
            <TrainingSetButton title={'Blue'} color={BLUE} size={SMALL_BUTTON} onPress={() => props.onPress('blue')} />
            <Spacer />
            <TrainingSetButton title={'Colorless'} color={CLESS} size={SMALL_BUTTON} onPress={() => props.onPress('colorless')} />
        </div>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
    </div>
)

const mapDispatchToProps = (dispatch: any) => ({
    onPress: (quizType: string) => {
        dispatch(trainerActions.OPENED_QUIZ_SCREEN({ quizType: quizType }))
    }
})

export const TrainingSetList = connect(null, mapDispatchToProps)(TrainingSetListPresentational)