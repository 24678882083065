import * as React from 'react'
import * as ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from './epics/rootEpic.js';
import 'rxjs';

const epicMiddleware = createEpicMiddleware()

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware),
    )
)

epicMiddleware.run(rootEpic)

const rootElement = document.getElementById('root')

const MainApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(
    <MainApp />
    ,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
