/**
 * Action interfaces and factories
 */

export type AnyAction = {
    readonly type: string;
}

/**
 * Action
 * Inspired by https://github.com/acdlite/flux-standard-action
 */
export type Action<Payload = undefined> = {
    readonly type: string;
    readonly payload: Payload;
}

/*
 * Usage:
 *   ```
 *   type TokenPayload = { readonly token: string }
 *   const [receivedToken, isReceivedToken] = actionOfType<TokenPayload>('RECEIVED_TOKEN')
 *   ```
 */
type ActionAndTypeCheck<Payload> = [
    (payload: Payload) => Action<Payload>,
    (action: AnyAction) => action is Action<Payload>
]

// tslint:disable-next-line:export-name
export function makeActionAndTypeGuard<Payload = undefined>(key: string): ActionAndTypeCheck<Payload> {
    return [
        (payload: Payload) => ({ type: key, payload: payload }),
        (action: AnyAction): action is Action<Payload> => action.type === key,
    ]
}

// Life

const INCREASED_LIFE = makeActionAndTypeGuard<{ readonly player: number }>('LIFE:INCREASED_LIFE')
const DECREASED_LIFE = makeActionAndTypeGuard<{ readonly player: number }>('LIFE:DECREASED_LIFE')
const RESET_LIFE = makeActionAndTypeGuard('LIFE:RESET_LIFE')

export const lifeActions = {
    INCREASED_LIFE: INCREASED_LIFE[0],
    DECREASED_LIFE: DECREASED_LIFE[0],
    RESET_LIFE: RESET_LIFE[0]
}

export const isLifeAction = {
    INCREASED_LIFE: INCREASED_LIFE[1],
    DECREASED_LIFE: DECREASED_LIFE[1],
    RESET_LIFE: RESET_LIFE[1]
}

// Dice

const ROLLED_DICE = makeActionAndTypeGuard('DICE:ROLLED_DICE')
const ENDED_ROLL = makeActionAndTypeGuard('DICE:ENDED_ROLL')

export const diceActions = {
    ROLLED_DICE: ROLLED_DICE[0],
    ENDED_ROLL: ENDED_ROLL[0]
}

export const isDiceAction = {
    ROLLED_DICE: ROLLED_DICE[1],
    ENDED_ROLL: ENDED_ROLL[1]
}

// Price

const OPENED_PRICER = makeActionAndTypeGuard('PRICE:OPENED_PRICER')
const CLOSED_PRICER = makeActionAndTypeGuard('PRICE:CLOSED_PRICER')
const SHOWED_CARD = makeActionAndTypeGuard('PRICE:SHOWED_CARD')
const REMOVED_CARD = makeActionAndTypeGuard('PRICE:REMOVED_CARD')
const ADDED_CARD_TO_LIST = makeActionAndTypeGuard<{card: any, isFoil: boolean}>('PRICE:ADDED_CARD_TO_LIST')
const CHANGED_TEXT_FIELD = makeActionAndTypeGuard<{text: string}>('PRICE:CHANGED_TEXT_FIELD')
const UPDATED_AUTOCOMPLETE = makeActionAndTypeGuard<{autocompleteList: Array<string>}>('PRICE:UPDATED_AUTOCOMPLETE')

export const priceActions = {
    OPENED_PRICER: OPENED_PRICER[0],
    CLOSED_PRICER: CLOSED_PRICER[0],
    SHOWED_CARD: SHOWED_CARD[0],
    REMOVED_CARD: REMOVED_CARD[0],
    ADDED_CARD_TO_LIST: ADDED_CARD_TO_LIST[0],
    CHANGED_TEXT_FIELD: CHANGED_TEXT_FIELD[0],
    UPDATED_AUTOCOMPLETE: UPDATED_AUTOCOMPLETE[0],
}

export const isPriceAction = {
    OPENED_PRICER: OPENED_PRICER[1],
    CLOSED_PRICER: CLOSED_PRICER[1],
    SHOWED_CARD: SHOWED_CARD[1],
    REMOVED_CARD: REMOVED_CARD[1],
    ADDED_CARD_TO_LIST: ADDED_CARD_TO_LIST[1],
    CHANGED_TEXT_FIELD: CHANGED_TEXT_FIELD[1],
    UPDATED_AUTOCOMPLETE: UPDATED_AUTOCOMPLETE[1],

}

// Training set

const OPENED_TRAINING_SET_SCREEN = makeActionAndTypeGuard('TRAINER:OPENED_TRAINING_SET_SCREEN')
const CLOSED_TRAINING_SET_SCREEN = makeActionAndTypeGuard('TRAINER:CLOSED_TRAINING_SET_SCREEN')
const OPENED_QUIZ_SCREEN = makeActionAndTypeGuard<{quizType: string}>('TRAINER:OPENED_QUIZ_SCREEN')
const CLOSED_QUIZ_SCREEN = makeActionAndTypeGuard('TRAINER:CLOSED_QUIZ_SCREEN')
const UPDATED_QUIZ_CARDS = makeActionAndTypeGuard<{quizCards: Array<{}>}>('TRAINER:UPDATED_QUIZ_CARDS')
const HIT_NEXT = makeActionAndTypeGuard('TRAINER:HIT_NEXT')
const HID_BLUR = makeActionAndTypeGuard('TRAINER:HID_BLUR')

export const trainerActions = {
    OPENED_TRAINING_SET_SCREEN: OPENED_TRAINING_SET_SCREEN[0],
    CLOSED_TRAINING_SET_SCREEN: CLOSED_TRAINING_SET_SCREEN[0],
    OPENED_QUIZ_SCREEN: OPENED_QUIZ_SCREEN[0],
    CLOSED_QUIZ_SCREEN: CLOSED_QUIZ_SCREEN[0],
    UPDATED_QUIZ_CARDS: UPDATED_QUIZ_CARDS[0],
    HIT_NEXT: HIT_NEXT[0],
    HID_BLUR: HID_BLUR[0],
}

export const isTrainerAction = {
    OPENED_TRAINING_SET_SCREEN: OPENED_TRAINING_SET_SCREEN[1],
    CLOSED_TRAINING_SET_SCREEN: CLOSED_TRAINING_SET_SCREEN[1],
    OPENED_QUIZ_SCREEN: OPENED_QUIZ_SCREEN[1],
    CLOSED_QUIZ_SCREEN: CLOSED_QUIZ_SCREEN[1],
    UPDATED_QUIZ_CARDS: UPDATED_QUIZ_CARDS[1],
    HIT_NEXT: HIT_NEXT[1],
    HID_BLUR: HID_BLUR[1],
}