import React from 'react';
//@ts-ignore types
import ReactDice from 'react-dice-complete'
import 'react-dice-complete/dist/react-dice-complete.css'
import { connect } from 'react-redux';
import { State, rootSelector } from '../reducers/rootReducer';
import { diceActions } from '../actions/actions';

type DispatchProps = {
    onCompleteRoll: () => void
}
type StateProps = {
    isDiceMode: boolean;
}

type Props = StateProps & DispatchProps

class DiceContainerPresentational extends React.Component<Props> {
    reactDice: any;
    constructor(props: Props) {
        super(props);
        this.rollAll = this.rollAll.bind(this);
    }
    rollAll() {
        this.reactDice.rollAll()
    }
    componentDidMount() {
        this.rollAll()
    }
    render() {
        return (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ReactDice
                    numDice={1}
                    ref={(dice: any) => this.reactDice = dice}
                    faceColor="#121212"
                    dotColor="#FFFFFF"
                    rollTime={1}
                    dieSize={100}
                    disableIndividual={true}
                    rollDone={() => setTimeout(this.props.onCompleteRoll, 1000)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: State) => {
    const isDiceMode = rootSelector(state).isDiceMode()
    return {
        isDiceMode: isDiceMode,
    }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    onCompleteRoll: () => dispatch(diceActions.ENDED_ROLL(undefined))
})

export const DiceContainer = connect(mapStateToProps, mapDispatchToProps)(DiceContainerPresentational)
