import React from 'react'
import { rootSelector, State } from '../reducers/rootReducer'
import { connect } from 'react-redux'
import { trainerActions } from '../actions/actions'

type StateProps = {
    isBlurred: boolean
}

type DispatchProps = {
    onClick: () => void
}

type Props = StateProps & DispatchProps

class BottomHalfBlockerPresentational extends React.Component {
    constructor(props: any) {
        super(props)
    }

    render() {
        //@ts-ignore
        if (this.props.isBlurred) {
            return (
                <div style={{ position: 'absolute', zIndex: 0, display: 'flex', alignItems: 'flex-end', height: '100%', width: '100%' }}>
                    <div
                        className='blur-box'
                        //@ts-ignore
                        onClick={this.props.onClick}
                    />
                </div>
            )
        } else {
            return null
        }
    }

}

const mapStateToProps = (state: State): StateProps => {
    const isBlurred = rootSelector(state).isBlurred()
    return {
        isBlurred: isBlurred
    }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    onClick: () => dispatch(trainerActions.HID_BLUR(undefined))
})

export const BottomHalfBlocker = connect(mapStateToProps, mapDispatchToProps)(BottomHalfBlockerPresentational)