import React from 'react';
import { lifeActions } from '../actions/actions';
import { connect } from 'react-redux';

type OwnProps = {
    shouldIncrement: boolean
    playerNumber: number
}

type DispatchProps = {
    readonly onClick: () => void
}

type Props = OwnProps & DispatchProps

const LifeCardButtonPresentational = (props: Props) => (
    <button onTouchStart={() => null} className="transparent-button" onClick={props.onClick} />
)

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
    if (ownProps.shouldIncrement) {
        return ({
            onClick: () => dispatch(lifeActions.INCREASED_LIFE({ player: ownProps.playerNumber }))
        })
    } else {
        return ({
            onClick: () => dispatch(lifeActions.DECREASED_LIFE({ player: ownProps.playerNumber }))
        })
    }
}

export const LifeCardButton = connect(null, mapDispatchToProps)(LifeCardButtonPresentational)