import { AnyAction, isLifeAction, isDiceAction, isPriceAction, isTrainerAction } from '../actions/actions'

export type State = {
    player_one_life: number,
    player_two_life: number,
    default_life_total: number,
    isDiceMode: boolean,

    isPriceMode: boolean,
    isCardShowing: boolean;
    currentTextField: string;
    autocompleteList: Array<string>;

    totalValue: number;
    addedCards: Array<{}>;

    isTrainingSetScreenOpen: boolean;
    isQuizScreenOpen: boolean;
    currentQuizType: string;
    currentQuizCards: Array<{}>;
    currentQuizPosition: number;
    isBlurred: boolean;
}

const defaultState: State = {
    player_one_life: 20,
    player_two_life: 20,
    default_life_total: 20,
    isDiceMode: false,

    isPriceMode: false,
    isCardShowing: false,
    currentTextField: '',
    autocompleteList: [],

    totalValue: 0,
    addedCards: [],

    isTrainingSetScreenOpen: false,
    isQuizScreenOpen: false,
    currentQuizType: '',
    currentQuizCards: [],
    currentQuizPosition: 0,
    isBlurred: true,
}

export const rootSelector = (state: State) => ({
    player_one_life: () => state.player_one_life,
    player_two_life: () => state.player_two_life,
    default_life_total: () => state.default_life_total,
    isDiceMode: () => state.isDiceMode,

    isPriceMode: () => state.isPriceMode,
    isCardShowing: () => state.isCardShowing,
    currentTextField: () => state.currentTextField,
    autocompleteList: () => state.autocompleteList,

    totalValue: () => state.totalValue,
    addedCards: () => state.addedCards,

    isTrainingSetScreenOpen: () => state.isTrainingSetScreenOpen,
    isQuizScreenOpen: () => state.isQuizScreenOpen,
    currentQuizType: () => state.currentQuizType,
    currentQuizCards: () => state.currentQuizCards,
    currentNumberOfQuizCards: () => state.currentQuizCards.length,
    quizCardAtPosition: () => state.currentQuizCards[state.currentQuizPosition],
    currentQuizPosition: () => state.currentQuizPosition,
    isBlurred: () => state.isBlurred,
})

export const rootReducer = (state: State = defaultState, action: AnyAction): State => {
    if (isLifeAction.DECREASED_LIFE(action)) {
        if (action.payload.player === 1) {
            return {
                ...state,
                player_one_life: state.player_one_life - 1
            }
        } else {
            return {
                ...state,
                player_two_life: state.player_two_life - 1
            }
        }
    } else if (isLifeAction.INCREASED_LIFE(action)) {
        if (action.payload.player === 1) {
            return {
                ...state,
                player_one_life: state.player_one_life + 1
            }
        } else {
            return {
                ...state,
                player_two_life: state.player_two_life + 1
            }
        }
    } else if (isLifeAction.RESET_LIFE(action)) {
        return {
            ...state,
            player_one_life: 20,
            player_two_life: 20,
        }
    } else if (isDiceAction.ROLLED_DICE(action)) {
        return {
            ...state,
            isDiceMode: true,
        }
    } else if (isDiceAction.ENDED_ROLL(action)) {
        return {
            ...state,
            isDiceMode: false,
        }
    } else if (isPriceAction.OPENED_PRICER(action)) {
        return {
            ...state,
            isPriceMode: true,
            isTrainingSetScreenOpen: false,
        }
    } else if (isPriceAction.CLOSED_PRICER(action)) {
        return {
            ...state,
            isPriceMode: false,
            isCardShowing: false,
        }
    } else if (isPriceAction.SHOWED_CARD(action)) {
        return {
            ...state,
            isCardShowing: true,
        }
    } else if (isPriceAction.REMOVED_CARD(action)) {
        return {
            ...state,
            isCardShowing: false,
        }
    } else if (isPriceAction.UPDATED_AUTOCOMPLETE(action)) {
        return {
            ...state,
            autocompleteList: action.payload.autocompleteList,
        }
    } else if (isPriceAction.ADDED_CARD_TO_LIST(action)) {
        return {
            ...state,
            addedCards: [...state.addedCards, { card: action.payload.card, isFoil: action.payload.isFoil }]
        }
    } else if (isPriceAction.CHANGED_TEXT_FIELD(action)) {
        return {
            ...state,
            currentTextField: action.payload.text
        }
    } else if (isTrainerAction.OPENED_TRAINING_SET_SCREEN(action)) {
        return {
            ...state,
            isPriceMode: false,
            isTrainingSetScreenOpen: true,
        }
    } else if (isTrainerAction.CLOSED_TRAINING_SET_SCREEN(action)) {
        return {
            ...state,
            isTrainingSetScreenOpen: false,
        }
    } else if (isTrainerAction.OPENED_QUIZ_SCREEN(action)) {
        return {
            ...state,
            isQuizScreenOpen: true,
            currentQuizType: action.payload.quizType,
        }
    } else if (isTrainerAction.CLOSED_QUIZ_SCREEN(action)) {
        return {
            ...state,
            isQuizScreenOpen: false,
            currentQuizCards: [],
            currentQuizPosition: 0,
            isBlurred: true,
        }
    } else if (isTrainerAction.UPDATED_QUIZ_CARDS(action)) {
        return {
            ...state,
            currentQuizCards: action.payload.quizCards,
            currentQuizPosition: 0,
        }
    } else if (isTrainerAction.HIT_NEXT(action)) {
        return {
            ...state,
            currentQuizPosition: state.currentQuizPosition + 1,
            isBlurred: true,
        }
    } else if (isTrainerAction.HID_BLUR(action)) {
        return {
            ...state,
            isBlurred: false,
        }
    } else {
        return state
    }
}
