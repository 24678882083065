import React from 'react';
import { BackButton } from './BackButton'
import search from '../assets/search_icon.png'
import { rootSelector, State } from '../reducers/rootReducer';
import { connect } from 'react-redux';
import { priceActions } from '../actions/actions';
import { AddToListButton } from './AddToListButton';
import { ValueCounter } from './ValueCounter';
import BarLoader from 'react-spinners/BarLoader'

//@ts-ignore
String.prototype.trunc = String.prototype.trunc ||
    function (n: any) {
        //@ts-ignore
        return (this.length > n) ? this.substr(0, n - 1) + '...' : this;
    };

type StateProps = {
    isPriceMode: boolean
    isCardShowing: boolean
    autocompleteList: Array<string>
    currentTextField: string;
}
type DispatchProps = {
    showCard: () => void
    removeCard: () => void
    onClickBack: () => void
    changedTextField: (text: string) => void
    changedAutocompleteList: (list: Array<string>) => void
}

type Props = StateProps & DispatchProps

class PriceModalPresentational extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
        this.state = {
            cardImage: '',
            isFoil: false,
            cardToAdd: {},

        }
        this.handleTextSubmit = this.handleTextSubmit.bind(this)
        this.handleOnTextInputChange = this.handleOnTextInputChange.bind(this)
        this.handleFocusField = this.handleFocusField.bind(this)
        this.toggleFoil = this.toggleFoil.bind(this)
    }

    componentDidMount() {
        document.getElementById('cardSearchField')?.focus()
    }

    toggleFoil() {
        //@ts-ignore
        this.setState({ isFoil: !this.state.isFoil })
    }

    handleFocusField() {
        //@ts-ignore
        document.getElementById('cardSearchField').value = ''
    }

    handleTextSubmit(cardname: any) {
        let prefix = 'https://api.scryfall.com/cards/named?fuzzy='
        // handle the empty case
        //@ts-ignore
        if (((document.getElementById('cardSearchField').value) as string).trim() === '' ||
            (this.props.autocompleteList.length < 1 || this.props.autocompleteList === undefined)) {
            cardname.preventDefault()
            //@ts-ignore
            document.getElementById('cardSearchField').value = ''
            //@ts-ignore
            document.activeElement.blur()
        } else {
            // handle if submitted without cardname
            if (typeof cardname !== "string") {
                cardname.preventDefault()
                // check if there's any available first results
                const firstCardName = this.props.autocompleteList[0]
                //@ts-ignore
                document.activeElement.blur()
                this.props.showCard()
                //@ts-ignore
                document.getElementById('cardSearchField').value = firstCardName
                fetch(prefix + firstCardName)
                    .then(res => res.json())
                    .then(result => {
                        this.setState({
                            cardImage: result.image_uris.border_crop,
                            cardToAdd: result,
                        })
                        this.props.changedAutocompleteList([])
                    })

            } else {
                //@ts-ignore dismiss keyboard
                document.activeElement.blur()
                this.props.showCard()
                //@ts-ignore
                document.getElementById('cardSearchField').value = cardname
                fetch(prefix + cardname)
                    .then(res => res.json())
                    .then(result => {
                        this.setState({
                            cardImage: result.image_uris.border_crop,
                            cardToAdd: result,
                        })
                        this.props.changedAutocompleteList([])
                    })
            }
        }
    }

    handleOnTextInputChange(e: any) {
        e.preventDefault()
        let cardname = e.target.value
        this.setState({ cardImage: '', isFoil: false })
        this.props.changedTextField(cardname)
        if (cardname.length < 2) {
            this.props.removeCard()
        }
    }

    render() {
        return (
            <div className='price-modal'>
                <BackButton onClick={this.props.onClickBack} />
                {/* <ValueCounter /> */}
                <form id="cardSearchForm" onSubmit={this.handleTextSubmit}>
                    <div id="cardSearchLabel">
                        <img id="cardSearchIcon" src={search} width={30} />
                        <input
                            id="cardSearchField"
                            type="text"
                            name="cardName"
                            placeholder="Search for a card"
                            onChange={this.handleOnTextInputChange}
                            autoCorrect="off"
                            spellCheck="false"
                            onFocus={this.handleFocusField}
                            onSubmit={() => console.log('submitted')}
                        />
                    </div>
                </form>
                {this.props.isCardShowing || this.props.currentTextField.length < 2 ? null :
                    <div id="autocompleteList">
                        {
                            //@ts-ignore
                            this.props.autocompleteList.map(
                                //@ts-ignore
                                (cardName: React.ReactNode) => <li key={cardName} id="cardAutocomplete" onClick={() => this.handleTextSubmit(cardName)}>{cardName.trunc(20)}</li>
                            )
                        }
                    </div>}
                {
                    this.props.isCardShowing ?
                        <div className="image-container">
                            <div style={{position: 'absolute', zIndex: -1}}>
                                <BarLoader width={150} color={'#006633'} />
                            </div>
                            <img src={(this.state as any).cardImage} height={400} />
                            {/* <div className="card-button-container">
                                {
                                    (this.state as any).cardToAdd.prices !== undefined ?
                                        (this.state as any).cardToAdd.prices.usd_foil !== null ?
                                            <div className={(this.state as any).isFoil ? "foil-toggle-on" : "foil-toggle-off"} onClick={this.toggleFoil}>
                                                Foil
                                    </div> : null : null
                                }
                                <AddToListButton card={(this.state as any).cardToAdd} isFoil={(this.state as any).isFoil} />
                            </div> */}
                        </div> : null
                }
            </div >
        )
    }
}

const mapStateToProps = (state: State): StateProps => {
    const isPriceMode = rootSelector(state).isPriceMode()
    const isCardShowing = rootSelector(state).isCardShowing()
    const autocompleteList = rootSelector(state).autocompleteList()
    const currentTextField = rootSelector(state).currentTextField()
    return {
        isPriceMode: isPriceMode,
        isCardShowing: isCardShowing,
        autocompleteList: autocompleteList,
        currentTextField: currentTextField,
    }
}

const mapDispatchToProps = (dispatch: any, ownProps: any): DispatchProps => ({
    showCard: () => dispatch(priceActions.SHOWED_CARD(undefined)),
    removeCard: () => dispatch(priceActions.REMOVED_CARD(undefined)),
    onClickBack: () => dispatch(priceActions.CLOSED_PRICER(undefined)),
    changedTextField: (text: string) => dispatch(priceActions.CHANGED_TEXT_FIELD({ text: text })),
    changedAutocompleteList: (list: Array<string>) => dispatch(priceActions.UPDATED_AUTOCOMPLETE({ autocompleteList: list }))
})


export const PriceModal = connect(mapStateToProps, mapDispatchToProps)(PriceModalPresentational as any)