import React from 'react';
import { connect } from 'react-redux';
import { priceActions } from '../actions/actions'
import backIcon from '../assets/back_icon.png'

type OwnProps = {
    onClick: () => void
}

type Props = OwnProps

export const BackButton = (props: Props) => (
    <div id="backButtonID" onClick={props.onClick}>
        <img src={backIcon} height="40" width="40"/>
    </div>
)
