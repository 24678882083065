import React from 'react'
import { Button } from './Button'
import refresh from '../assets/refresh_icon.png'
import die from '../assets/die_icon.png'
import { connect } from 'react-redux'
import { State, rootSelector } from '../reducers/rootReducer'
import { lifeActions, diceActions, priceActions, trainerActions } from '../actions/actions'
import brain from '../assets/brain_icon.png'
import search from '../assets/search_icon.png'

type StateProps = {
    isDiceMode: boolean;
}

type DispatchProps = {
    onClickRefresh: () => void;
    onClickRollDice: () => void;
    onClickOpenPricer: () => void;
    onClickOpenTrainer: () => void;
}

type Props = StateProps & DispatchProps

const CenterConsolePresentational = (props: Props) => (
    <div className="CenterConsole">
        <Button
            side="console-button-left"
            onClick={props.onClickRollDice}
            image={die}
            disabled={props.isDiceMode}
        />
        <Button side="console-button-center" onClick={props.onClickRefresh} image={refresh} />
        <Button image={search} side="console-button-center" onClick={props.onClickOpenPricer} />
        <Button image={brain} side="console-button-right" onClick={props.onClickOpenTrainer} />
    </div>
)

const mapStateToProps = (state: State): StateProps => {
    const isDiceMode = rootSelector(state).isDiceMode()
    return {
        isDiceMode: isDiceMode
    }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    onClickRefresh: () => dispatch(lifeActions.RESET_LIFE(undefined)),
    onClickRollDice: () => dispatch(diceActions.ROLLED_DICE(undefined)),
    onClickOpenPricer: () => dispatch(priceActions.OPENED_PRICER(undefined)),
    onClickOpenTrainer: () => dispatch(trainerActions.OPENED_TRAINING_SET_SCREEN(undefined))
})

export const CenterConsole = connect(mapStateToProps, mapDispatchToProps)(CenterConsolePresentational)
