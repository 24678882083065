import { combineEpics } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators'
import { isPriceAction, priceActions, isTrainerAction, trainerActions } from '../actions/actions';
import { ajax } from 'rxjs/ajax'
import { shuffle } from '../utilities'

const AUTOCOMPLETE_PREFIX = 'https://api.scryfall.com/cards/autocomplete?q='

const SORT_ORDERS = ['edhrec', 'artist', 'usd']
const RANDOM_SORT = SORT_ORDERS[Math.floor(Math.random() * SORT_ORDERS.length)];

const CURRENT_SET = 'iko'

const getQuizColor = (quizType) => {
    switch(quizType){
        case 'white':
            return '>%3Dw'
        case 'black':
            return '>%3Db'
        case 'green':
            return '>%3Dg'
        case 'red':
            return '>%3Dr'
        case 'blue':
            return '>%3Du'
        case 'colorless':
            return '%3Dc'
        default:
            return 'c'
    }
}

// gets randomly sorted from sort list cards that are in CURRENT_SET
const QUIZ_URL = (quizType) => `https://api.scryfall.com/cards/search?order=${RANDOM_SORT}&q=-type%3Aland+e%3A${CURRENT_SET}+color${getQuizColor(quizType)}`

const fetchAutocompleteEpic = action$ => action$.pipe(
    filter(action => isPriceAction.CHANGED_TEXT_FIELD(action)),
    switchMap(action => ajax.getJSON(`${AUTOCOMPLETE_PREFIX}${action.payload.text}`).pipe(
        map(response => priceActions.UPDATED_AUTOCOMPLETE({ autocompleteList: response.data.slice(0, 6) }))
    ))
)

const fetchQuizCardsEpic = action$ => action$.pipe(
    filter(action => isTrainerAction.OPENED_QUIZ_SCREEN(action)),
    switchMap(action => ajax.getJSON(`${QUIZ_URL(action.payload.quizType)}`).pipe(
        map(response => trainerActions.UPDATED_QUIZ_CARDS({ quizCards: shuffle(response.data) }))
    ))
)

export const rootEpic = combineEpics(
    fetchAutocompleteEpic,
    fetchQuizCardsEpic,
)