import React from 'react';

type Props = {
    side?: string
    onClick?: () => void
    disabled?: boolean
    image?: string
    text?: string
}

export const Button = (props: Props) => {
    return (
        <button className={props.side} onClick={props.onClick} disabled={props.disabled}>
            {props.image === '' ? null : <img src={props.image} height="40" width="40" />}
            {props.text}
        </button>
    );
}

