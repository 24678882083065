import React from 'react';
import { connect } from 'react-redux';
import { rootSelector, State } from '../reducers/rootReducer';
import { BackButton } from './BackButton';
import { trainerActions } from '../actions/actions';
import PulseLoader from 'react-spinners/PulseLoader'
import { QuizCard } from './QuizCard';
import { Button } from './Button';

const getQuizTitle = (quizType: string) => {
    switch (quizType) {
        case 'white':
            return 'White'
        case 'black':
            return 'Black'
        case 'green':
            return 'Green'
        case 'red':
            return 'Red'
        case 'blue':
            return 'Blue'
        case 'colorless':
            return 'Colorless'
        case 'random_10':
            return 'Random 10'
        case 'my_misses':
            return 'My Misses'
        default:
            return 'Quiz'
    }
}

type StateProps = {
    isQuizScreenOpen: boolean;
    currentQuizType: string;
    currentQuizPosition: number;
    numberOfQuizCards: number;
}

type DispatchProps = {
    onClick: () => void;
    onClickNext: () => void;
}

type Props = StateProps & DispatchProps

const QuizScreenPresentational = (props: Props) => (
    <div className='quiz-modal'>
        <div style={{ display: 'flex', width: '100%' }}>
            <BackButton onClick={props.onClick} />
        </div>
        <div style={{ fontSize: 12, color: '#828282' }}>
            {getQuizTitle(props.currentQuizType)}
        </div>
        <div style={{ fontSize: 22, color: 'white', display: 'flex', flexDirection: 'row' }}>
            {props.currentQuizPosition + 1} of {props.numberOfQuizCards < 1 ? <div style={{ marginLeft: 10 }}><PulseLoader size={5} color={'#006633'} /></div> : props.numberOfQuizCards}
        </div>
        <QuizCard />
        {
            props.numberOfQuizCards < 1 || props.currentQuizPosition > props.numberOfQuizCards - 1 ? null :
                props.currentQuizPosition === props.numberOfQuizCards - 1 ?
                    <Button disabled={false} side={'finish-button'} onClick={props.onClick} image='' text='Finish' /> :
                    <Button disabled={false} side={'next-button'} onClick={props.onClickNext} image='' text='Next' />
        }
    </div>

)

const mapStateToProps = (state: State): StateProps => {
    const isQuizScreenOpen = rootSelector(state).isQuizScreenOpen()
    const currentQuizType = rootSelector(state).currentQuizType()
    const currentQuizPosition = rootSelector(state).currentQuizPosition()
    const numberOfQuizCards = rootSelector(state).currentNumberOfQuizCards()
    return {
        isQuizScreenOpen: isQuizScreenOpen,
        currentQuizType: currentQuizType,
        currentQuizPosition: currentQuizPosition,
        numberOfQuizCards: numberOfQuizCards,
    }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    onClick: () => dispatch(trainerActions.CLOSED_QUIZ_SCREEN(undefined)),
    onClickNext: () => dispatch(trainerActions.HIT_NEXT(undefined))
})

export const QuizScreen = connect(mapStateToProps, mapDispatchToProps)(QuizScreenPresentational)